import { useFuse, type UseFuseOptions } from '@vueuse/integrations/useFuse'

export const useGetMapToBibleBook = (
  bookName: string,
  bibleBooks: string[],
) => {
  try {
    const options = {
      includeScore: true,
      findAllMatches: true,
      threshold: 0.3,
    }
    // @TODO get options working
    const { results } = useFuse(
      ref(bookName),
      ref(bibleBooks),
      options as UseFuseOptions<string>,
    )
    return results.value?.[0]?.item
  } catch (e) {
    console.error(e)
    return null
  }
}
