import { AssistantType } from '~/enums'
export const useGetSuggestedQuestions = (
  assistantType: AssistantType,
  context: string,
  translate: any,
) => {
  switch (assistantType) {
    case AssistantType.HISTORY:
      return getHistorySuggestedQuestions(context, translate)
    case AssistantType.STUDY:
      return getBibleStudySuggestedQuestions(context, translate)
    case AssistantType.ALPHA:
      return getAlphaSuggestedQuestions(context, translate)
    case AssistantType.ORIGINAL_TEXT:
      return getOriginalTextSuggestedQuestions(context, translate)
    case AssistantType.SCHOLAR:
      return getScholarSuggestedQuestions(context, translate)
    default:
      return getGeneralSuggestedQuestions(translate)
  }
}

const getGeneralSuggestedQuestions = (translate: any) => {
  return [
    {
      label: translate('related_scriptures_label'),
      question: `${translate('related_scriptures_question')}`,
    },
    {
      label: translate('summary_label'),
      question: `${translate('summary_question')}`,
    },
    {
      label: translate('bible_study_questions_label'),
      question: `${translate('bible_study_questions_question')}`,
    },
    {
      label: translate('background_info_label'),
      question: `${translate('background_info_question')}`,
    },
  ]
}

const getHistorySuggestedQuestions = (context: string, translate: any) => {
  return [
    {
      label: translate('historical_context'),
      question: `${translate('historical_context_question')} ${context}?`,
    },
    {
      label: translate('customs'),
      question: `${translate('customs_question')} ${context}?`,
    },
    {
      label: translate('culture'),
      question: `${translate('culture_question')} ${context}?`,
    },
  ]
}

const getBibleStudySuggestedQuestions = (context: string, translate: any) => {
  return [
    {
      label: translate('perspectives'),
      question: `${translate('perspectives_question')} ${context}?`,
    },
    {
      label: translate('related_texts'),
      question: `${translate('related_texts_question')} ${context}?`,
    },
    {
      label: translate('practical_application'),
      question: `${translate('practical_application_question')} ${context}?`,
    },
    {
      label: translate('study_suggestions'),
      question: `${translate('study_suggestions_question')}`,
    },
  ]
}

const getAlphaSuggestedQuestions = (context: string, translate: any) => {
  return [
    {
      label: translate('summary'),
      question: `${translate('summary_question')} ${context}?`,
    },
    {
      label: translate('simple_explanation'),
      question: `${translate('simple_explanation_question')} ${context}?`,
    },
    {
      label: translate('application'),
      question: `${translate('application_question')}`,
    },
    {
      label: translate('learn'),
      question: `${translate('learn_question')}`,
    },
  ]
}

const getOriginalTextSuggestedQuestions = (context: string, translate: any) => {
  return [
    {
      label: translate('original'),
      question: `${translate('original_question')} ${context}?`,
    },
    {
      label: translate('interpretations'),
      question: `${translate('interpretations_question')} ${context}?`,
    },
  ]
}

const getScholarSuggestedQuestions = (context: string, translate: any) => {
  return [
    {
      label: translate('criticism'),
      question: `${translate('criticism_question')}`,
    },
    {
      label: translate('theological_context'),
      question: `${translate('theological_context_question')} ${context}?`,
    },
    {
      label: translate('exegesis'),
      question: `${translate('exegesis_question')} ${context}?`,
    },
    {
      label: translate('hermeneutics'),
      question: `${translate('hermeneutics_question')}`,
    },
  ]
}
