import type { BibleChapterParagraph } from 'global'

export const useFormatBibleChapter = (
  currentChapterContent: BibleChapterParagraph[],
): string => {
  let formattedVerses = ''
  let currentVerse = ''
  let currentText = ''

  currentChapterContent.forEach((p) => {
    p.content?.forEach((verse) => {
      if (verse.type === 'verse-number') {
        // If we already have some text, append the previous verse and its text
        if (currentText) {
          formattedVerses += `Vers ${currentVerse}: ${currentText.trim()} `
        }
        // Start a new verse
        currentVerse = verse.content
        currentText = ''
      } else if (verse.type === 'verse-text') {
        // Add space before the text if it's not the first text in the verse
        if (currentText) currentText += ' '
        currentText += verse.content
      }
    })
  })

  return formattedVerses.trim() // Trim the final string to remove any trailing spaces
}

// Example usage remains the same, but now it will return a string as desired.
