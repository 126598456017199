<script lang="ts" setup>
const { t } = useI18n()

const sourceUlr = computed(() => {
  switch (t('id')) {
    case 'nl-NL':
      return '/nbg-logo.svg'
    case 'fi-FI':
      return '/raamattu.png'
    default:
      return '/nbg-logo.svg'
  }
})
</script>
<template>
  <img :src="sourceUlr" alt="Logo" class="h-9 w-9" />
</template>
