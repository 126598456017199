import type { FormattedBibleVerse } from 'global'

export const useGetContextPrompt = (
  currentBookName: string,
  currentChapter: string,
  selectedVerses: FormattedBibleVerse[],
  translate: any,
) => {
  let bookContext = ''
  if (currentBookName && currentChapter) {
    bookContext = `${translate('reader_is_reading')} ${currentBookName} ${currentChapter}`
    if (selectedVerses.length) {
      bookContext += ` ${translate('selected_verses')} ${selectedVerses}`
    }
  }
  return bookContext
}
