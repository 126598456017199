<script lang="ts" setup>
import type { Message, NamedSource, StreamMessage } from 'global'
import { v4 as uuidv4 } from 'uuid'
import { useI18n } from 'vue-i18n'
import { useBibleStore } from '~/store/bible'
import { useAssistantStore } from '~/store/assistant'
import { useGetContextPrompt } from '~/composables/useGetContextPrompt'
import { useConfigStore } from '~/store/config'

const { t } = useI18n()

const assistantStore = useAssistantStore()
const { assistantType } = storeToRefs(assistantStore)

const configStore = useConfigStore()
const {
  customPrompt,
  useCustomPrompt,
  selectedModel,
  database,
  numRelevantDocs,
} = storeToRefs(configStore)

const systemPrompt = computed(() => {
  let system = useGetSystemPrompt(t)
  if (useCustomPrompt.value) {
    system = customPrompt.value
  }
  return (
    system +
    ' ' +
    useGetContextPrompt(
      currentBookName.value,
      currentChapter.value,
      selectedVerses.value,
      t,
    )
  )
})
const biblicalContext = computed(() => {
  return `${currentBookName.value} ${currentChapter.value}: ${useFormatBibleChapter(currentChapterContent.value)}`
})
const bibleStore = useBibleStore()
const {
  currentBookName,
  currentChapter,
  selectedVerses,
  currentChapterContent,
} = storeToRefs(bibleStore)

const verses = computed(() => {
  return selectedVerses.value.map((verse) => verse.verseNumber).join(',')
})
const selectedBibleVerses = computed(() => {
  return `${currentBookName.value} ${currentChapter.value}${
    verses.value ? ':' + verses.value : ''
  }`
})

const exampleQuestions = computed(() => {
  return useGetSuggestedQuestions(
    assistantType.value,
    selectedBibleVerses.value,
    t,
  )
})

const followUpQuestions = [
  {
    label: t('simplify'),
    question: t('simplify_question'),
  },
  {
    label: t('scriptures'),
    question: t('scriptures_question'),
  },
]

const messages = ref([
  {
    id: '-1',
    role: 'AI',
    message: [{ id: -1, content: t('chat_initial_message') }],
    sources: [] as NamedSource[],
  },
] as Message[])
const loading = ref(false)
const message = ref('')
const showRetry = ref(false)

const scrollToEnd = () => {
  setTimeout(() => {
    const chatMessages = document.querySelector(
      '.chat-messages > div:last-child',
    )
    chatMessages?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, 100)
}

const sendPrompt = async () => {
  if (message.value === '' && !showRetry) return
  showRetry.value = false
  loading.value = true

  messages.value.push({
    id: uuidv4(),
    role: 'User',
    message:
      message.value || messages.value?.[messages.value?.length - 2]?.message,
    sources: [] as NamedSource[],
  })

  scrollToEnd()
  questionEventGTM(message.value)
  message.value = ''
  await fetchStream()
  loading.value = false
  scrollToEnd()
}

const gtm = useGtm()
function questionEventGTM(question: string) {
  gtm?.trackEvent({
    event: 'question_asked',
    category: 'chat',
    action: 'click',
    label: 'Question asked',
    value: { question },
    noninteraction: false,
  })
}

function feedbackEventGTM() {
  gtm?.trackEvent({
    event: 'feedback_clicked',
    category: 'chat',
    action: 'click',
    label: 'Feedback form clicked',
    noninteraction: false,
  })
}

async function fetchStream() {
  const newMessages = ref([] as StreamMessage[])
  const newSources = ref([] as NamedSource[])
  messages.value.push({
    id: uuidv4(),
    role: 'AI',
    message: newMessages,
    sources: newSources.value,
  })
  try {
    const response = await fetch('/api/chat/chat-stream', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        messages: messages.value.slice(1),
        systemPrompt: systemPrompt.value,
        biblicalContext: biblicalContext.value,
        selectedBibleVerses: selectedBibleVerses.value,
        question: `${messages.value[messages.value.length - 2].message}`,
        questionId: messages.value[messages.value.length - 2].id,
        language: t('id'),
        model: selectedModel.value,
        database: database.value,
        numRelevantDocs: numRelevantDocs.value,
      }),
    })
    if (response.ok) {
      const reader = response.body?.getReader()
      if (!reader) {
        throw new Error('Failed to get reader')
      }
      const decoder = new TextDecoder()
      let result = ''
      while (true) {
        const { done, value } = await reader.read()
        if (done) break

        // Decode the stream chunk
        const chunk = decoder.decode(value, { stream: true })
        result += chunk

        // Process complete lines
        let eolIndex
        while ((eolIndex = result.indexOf('\n')) >= 0) {
          const line = result.substring(0, eolIndex).trim()
          result = result.substring(eolIndex + 1)
          if (line.startsWith('data: ')) {
            const data = JSON.parse(line.substring('data: '.length))
            if (data.answer) {
              newMessages.value.push({ id: Date.now(), content: data.answer })
            }
            if (data.documents) {
              const cleanedUrls = data.documents.map(
                (doc: { source: string }) => {
                  return doc.source?.split('?')[0] // Remove all url parameters
                },
              )
              const uniqueSources = new Set<string>(cleanedUrls)
              const namedSources = Array.from(uniqueSources).map(
                (source: string) => {
                  return {
                    name: useConvertUrlToName(
                      source.substring(source.lastIndexOf('/') + 1),
                    ),
                    source,
                  } as NamedSource
                },
              )
              newSources.value.push(...namedSources) // Add the sources to the sources array
            }
          } else if (line === 'event: end') {
            reader.cancel() // Cancel the stream reader
            loading.value = false
            break
          }
        }
      }
    } else {
      throw new Error('Failed to read the stream')
    }
    if (newMessages.value.length === 0) {
      newMessages.value.push({
        id: Date.now(),
        content: t('error_something_went_wrong'),
      })
    }
  } catch (error) {
    console.log('Failed to read the stream', error)
    newMessages.value.push({
      id: Date.now(),
      content: t('error_something_went_wrong'),
    })
    loading.value = false
  }
}
</script>

<template>
  <div class="w-full h-full flex grow mx-auto text-black">
    <div
      class="bg-white dark:bg-gray-700 w-full h-full shadow flex flex-col justify-between"
    >
      <div class="h-full overflow-y-auto chat-messages">
        <div class="flex flex-col p-4 pb-0 mb-8">
          <div class="chat flex grow justify-center">
            <NuxtLink
              class="btn btn-2xl btn-primary"
              :to="$t('nav_feedback_url')"
              @click="feedbackEventGTM"
              >{{ $t('give_us') }} 💬</NuxtLink
            >
          </div>
        </div>
        <div
          v-for="(chatMessage, index) in messages"
          :key="index"
          class="flex flex-col p-4"
        >
          <div v-if="chatMessage.role === 'AI'" class="md:pr-8 md:mr-auto">
            <div class="chat chat-start flex grow items-end">
              <div class="chat-image avatar">
                <div
                  :class="[
                    'w-10 rounded-full',
                    chatMessage.id !== '-1' ? 'mb-6' : '',
                  ]"
                >
                  <ChatNBGIcon :icon-id="`ai-index`" />
                </div>
              </div>
              <div class="flex flex-col">
                <div
                  class="chat-bubble bg-gray-800 dark:bg-gray-800 text-gray-200 dark:text-white mb-2"
                >
                  <div
                    v-show="
                      loading &&
                      index === messages.length - 1 &&
                      chatMessage.message?.length === 0 &&
                      chatMessage.sources.length === 0
                    "
                    class="p-4 w-full"
                  >
                    <!-- <span class="loader"></span> -->
                    <div class="flex justify-center">
                      <h1
                        class="text-md md:text-md font-semibold flex flex-col items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 animate-pulse mb-2"
                        >
                          <path
                            d="M11.625 16.5a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm6 16.5c.66 0 1.277-.19 1.797-.518l1.048 1.048a.75.75 0 0 0 1.06-1.06l-1.047-1.048A3.375 3.375 0 1 0 11.625 18Z"
                            clip-rule="evenodd"
                          />
                          <path
                            d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z"
                          />
                        </svg>
                        <span class="text-center">{{
                          $t('searching_documents')
                        }}</span>
                      </h1>
                    </div>
                  </div>
                  <ChatMessage
                    :class="index !== 0 ? 'my-4' : 'mt-0'"
                    :chat-message="chatMessage.message"
                  />
                  <div
                    v-show="
                      loading &&
                      index === messages.length - 1 &&
                      (chatMessage.message?.length > 0 ||
                        chatMessage.sources.length > 0)
                    "
                    class="flex space-x-2 mt-4 justify-center items-center dark:invert"
                  >
                    <span class="sr-only">Loading...</span>
                    <div
                      class="h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"
                    ></div>
                    <div
                      class="h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"
                    ></div>
                    <div
                      class="h-2 w-2 bg-white rounded-full animate-bounce"
                    ></div>
                  </div>
                  <div
                    v-if="chatMessage.sources.length > 0"
                    class="text-xs mt-2"
                  >
                    <div class="collapse collapse-arrow">
                      <input type="checkbox" />
                      <div class="collapse-title pl-0">{{ $t('sources') }}</div>
                      <div class="collapse-content pl-0">
                        <ul>
                          <li
                            v-for="source in chatMessage.sources"
                            :key="source.source"
                          >
                            <a :href="source.source" target="_blank">{{
                              source.name
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap flex-col-reverse md:flex-row">
                  <div
                    v-show="
                      index !== 0 &&
                      !chatMessage.message.includes(
                        $t('error_something_went_wrong'),
                      )
                    "
                    class="flex gap-2 flex-wrap mt-2"
                  >
                    <button
                      v-for="(question, index) in followUpQuestions"
                      :key="index"
                      :disabled="loading"
                      class="text-left btn btn-sm rounded-full btn-outline dark:bg-gray-800 dark:text-gray-100 dark:hover:text-gray-800 dark:hover:bg-gray-100 hover:bg-sky-50 hover:text-blue-800"
                      @click="(message = question.question), sendPrompt()"
                    >
                      {{ question.label }}
                    </button>
                  </div>
                  <ChatFeedbackAnswer
                    v-show="chatMessage.id !== '-1'"
                    :message-id="chatMessage.id"
                    class="ml-auto mr-8"
                  />
                </div>
              </div>
            </div>
            <div v-show="index === 0" class="flex gap-2 flex-wrap mt-2">
              <button
                v-for="(question, index) in exampleQuestions"
                :key="index"
                :disabled="loading"
                class="text-left btn btn-sm rounded-full btn-outline dark:bg-gray-800 dark:text-gray-100 dark:hover:text-gray-800 dark:hover:bg-gray-100 hover:bg-sky-50 hover:text-blue-800"
                @click="(message = question.question), sendPrompt()"
              >
                {{ question.label }}
              </button>
            </div>
          </div>

          <div v-else class="pl-8 ml-auto flex flex-col items-end">
            <div class="chat chat-end flex grow">
              <div
                class="chat-bubble bg-blue-700 dark:bg-blue-800 text-gray-100"
              >
                {{ chatMessage.message }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="sendPrompt">
        <div class="flex items-center w-full p-4 pb-2">
          <input
            v-model="message"
            type="text"
            :placeholder="`${$t('ask_something')} ${selectedBibleVerses}...`"
            class="text-base w-full p-3 text-black placeholder-gray-500 dark:placeholder-gray-400 placeholder-opacity-50 dark:text-gray-100 bg-slate-100 dark:bg-slate-500 rounded-full grow"
          />
        </div>
      </form>
      <span class="text-gray-400 text-xs mx-3 p-2 text-center pt-0">
        {{ $t('disclaimer') }}
      </span>
    </div>
  </div>
</template>

<style>
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: #d3d3d3;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}
a {
  color: #3b82f6;
}

@keyframes animloader {
  0% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 -2px,
      -38px 0 0 -2px;
  }
  25% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 -2px,
      -38px 0 0 2px;
  }
  50% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 2px,
      -38px 0 0 -2px;
  }
  75% {
    box-shadow:
      14px 0 0 2px,
      38px 0 0 -2px;
  }
}
</style>
