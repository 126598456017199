<script setup lang="ts">
import type { StreamMessage } from 'global'
import { useBibleStore } from '~/store/bible'

const props = defineProps({
  chatMessage: { type: Array as () => StreamMessage[], required: true },
})

const { t } = useI18n()

const bibleStore = useBibleStore()
const {
  setCurrentBibleBook,
  setCurrentBibleChapter,
  setCurrentChapterContent,
} = bibleStore
const { books, scriptureRegex } = storeToRefs(bibleStore)

const parsedContent = computed(() => {
  return parseMessage(props.chatMessage)
})

const drawerState = useChatDrawerState()
const selectScripture = (reference: string) => {
  // use local regex (instead of the one from the store) because global regex maintains state resulting in returning null every other time it is called
  const regex = /(\b[123]?\s?[A-Za-zë]+\b)\s+(\d+)(?::(\d+))?/i
  const match = regex.exec(reference)
  try {
    if (match) {
      const [, book, chapter] = match
      const mappedBookName = useGetMapToBibleBook(
        book,
        books.value?.map((book) => book?.name),
      )
      setCurrentBibleBook(mappedBookName)
      setCurrentBibleChapter(chapter)
      // @todo Update the selected verses
      setCurrentChapterContent(t('brand'))
      drawerState.value = false // Close drawer if open on mobile
    } else {
      console.error('Invalid scripture reference format')
    }
  } catch (e) {
    useBugsnag().notify(error, {
      severity: 'error',
      context: 'Failed to navigate to the selected scripture',
      metaData: {
        scripture: reference,
      },
    })
  }
}

// Cut the message into parts based on scripture references
// So every scripture reference is a separate part and all the other texts are separate parts
const parseMessage = (message: StreamMessage[]) => {
  const formattedMessage = formattedContent(message)
  const parts = []
  let lastIndex = 0

  // Use the replace method to find scripture references and their positions
  let matches = [] as RegExpExecArray[]
  if (t('id') !== 'fi-FI') {
    // @todo fix hardcoded removal of scripture references
    matches = [...formattedMessage.matchAll(scriptureRegex.value)]
    matches = matches.filter((match) => {
      const book = match[1]
      return useGetMapToBibleBook(
        book,
        books.value?.map((book) => book?.name),
      )
    })
  }

  matches.forEach((matchArray, index) => {
    const match = matchArray[0]
    const offset = matchArray.index || 0
    // Add text before the scripture reference as 'text'
    if (offset > lastIndex) {
      parts.push({
        type: 'text',
        content: formattedMessage.substring(lastIndex, offset),
      })
    }
    // Add the scripture reference as 'scripture'
    parts.push({
      type: 'scripture',
      content: match,
    })
    // Update lastIndex to the end of the current scripture reference
    lastIndex = offset + match.length
    // If this is the last match, also add the remaining text after the scripture reference
    if (index === matches.length - 1 && lastIndex < formattedMessage.length) {
      parts.push({
        type: 'text',
        content: formattedMessage.substring(lastIndex),
      })
    }
  })

  // If no matches were found, the entire content is text
  if (matches.length === 0) {
    parts.push({
      type: 'text',
      content: formattedMessage,
    })
  }
  return parts
}

// format enters and bold text @todo add more markdown support
const formattedContent = (messages: StreamMessage[]): string => {
  const completeMessage = messages.map((msg) => msg.content).join('')
  let content = completeMessage.replace(/\n/g, '<br>')
  content = content.replace(/markdown/g, '')
  // Ensure headers are processed correctly in order
  content = content.replace(/(######)\s(.+)$/gm, '<h6>$2</h6>')
  content = content.replace(/(#####)\s(.+)$/gm, '<h5>$2</h5>')
  content = content.replace(/(####)\s(.+)$/gm, '<h4>$2</h4>')
  content = content.replace(
    /(###)\s(.+)$/gm,
    '<h3 class="text-xl font-bold">$2</h3>',
  )
  content = content.replace(
    /(##)\s(.+)$/gm,
    '<h2 class="text-2xl font-bold">$2</h2>',
  )
  content = content.replace(
    /(#)\s(.+)$/gm,
    '<h1 class="text-4xl font-bold">$2</h1>',
  )

  // Handle bold text
  content = content.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')

  // Additional handling for lists
  content = content.replace(/-\s(.+)$/gm, '<li>$1</li>')
  content = content.replace(/<br>\s*<li>/g, '<ul><li>')
  content = content.replace(/<\/li>\s*<br>/g, '</li></ul><br>')

  return content
}
</script>
<template>
  <div>
    <span v-for="(part, index) in parsedContent" :key="`message-part-${index}`">
      <template v-if="part.type === 'text'">
        <span class="text-white" v-html="part.content"></span>
      </template>
      <template v-else-if="part.type === 'scripture'">
        <span
          class="text-info cursor-pointer"
          @click="selectScripture(part.content)"
        >
          {{ part.content }}
        </span>
      </template>
    </span>
  </div>
</template>
